.price-chart {
    margin: 0px max(5vw, calc((100vw - 1600px) / 2)) 30px;
    height: 500px;
}

.price-chart-interval {
    margin: 10px max(5vw, calc((100vw - 1600px) / 2));
    display: flex;
}

.price-chart-interval-divider {
    margin: 0px 15px;
    height: 50px;
    display: flex;
}

.price-chart-interval-button-untoggled {
    background-color: black;
    cursor: pointer;
    color: white;
    width: 100px;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    transition: 0.3s;
}

.price-chart-interval-button-untoggled:hover {
    background-color: #222222;
}

.price-chart-interval-button-toggled {
    background-color: rgb(35, 138, 255);
    cursor: pointer;
    color: black;
    width: 100px;
    border-radius: 15px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-weight: bold;
    transition: 0.3s;
}

.price-chart-interval-button-toggled:hover {
    background-color: rgb(29, 114, 212);
}

.price-chart-divider {
    margin: 20px max(5vw, calc((100vw - 1600px) / 2)) 40px;
    width: 100vw - 1600px;
    height: 2px;
    background-color: #1c1c1c;
}

.quote-header-diff-green {
    margin: 0px max(5vw, calc((100vw - 1600px) / 2)) 4rem;
    font-size: 18px;
    color: rgb(0, 185, 0);
}

.quote-header-diff-red {
    margin: 0px max(5vw, calc((100vw - 1600px) / 2)) 4rem;
    font-size: 18px;
    color: red;
}

@media (max-width: 1320px) {
    .price-chart {
        margin: 0px 5% 20px;
        height: 400px;
    }
    
    .price-chart-interval {
        margin: 0px 5%;
    }

    .price-chart-interval-divider {
        margin: 0px 10px;
        height: 40px;
    }

    .price-chart-divider {
        margin: 20px 5% 20px 5%;
        width: 90%;
        height: 1px;
    }

    .price-chart-interval-button-untoggled {
        width: 80px;
        border-radius: 9px;
        font-size: 10px;
    }

    .price-chart-interval-button-toggled {
        width: 80px;
        border-radius: 9px;
        font-size: 10px;
    }

    .quote-header-diff-green {
        margin: 0px 5% 20px;
        font-size: max(14px, calc(4px + 1vmin));
    }
    
    .quote-header-diff-red {
        margin: 0px 5% 20px;
        font-size: max(14px, calc(4px + 1vmin));
    }
}

@media (max-width: 767px) {
    .price-chart {
        margin: 0px 0% 20px;
        height: 300px;
    }

    .price-chart-interval-divider {
        margin: 0px 5px;
        height: 30px;
    }

    .price-chart-interval-button-untoggled {
        width: 60px;
    }

    .price-chart-interval-button-toggled {
        width: 60px;
    }

    .quote-header-diff-green {
        font-size: max(12px, calc(4px + 1vmin));
    }
    
    .quote-header-diff-red {
        font-size: max(12px, calc(4px + 1vmin));
    }
}