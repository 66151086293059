.quote-tile {
    background-color: black;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 8px #444444;
    width: 100%;
    height: 65px;
    display: inline-block;
}

.quote-tile-title {
    color: #cccccc;
    float: left;
    opacity: 50%;
    font-size: 24px;
    padding: 1.15rem 0 0 1.15rem;
}

.quote-tile-data {
    color: white;
    float: right;
    font-size: 24px;
    padding: 1.15rem 1.15rem 0 0;
}

@media (max-width: 1320px) {
    .quote-tile {
        border-radius: 9px;
        height: calc(8px + 5vmin);
    }
    
    .quote-tile-title {
        font-size: calc(10px + 1vmin);
        padding: 1.5vmin;
    }
    
    .quote-tile-data {
        font-size: calc(10px + 1vmin);
        padding: 1.5vmin;
    }
}

@media (max-width: 767px) {
    .quote-tile {
        height: calc(10px + 5vmin);
    }
    
    .quote-tile-title {
        font-size: calc(4px + 1.5vmin);
        padding: calc(2px + 1.5vmin);
    }
    
    .quote-tile-data {
        font-size: calc(4px + 1.5vmin);
        padding: calc(2px + 1.5vmin);
    }
}