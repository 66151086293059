.input-box {
    background-color: black;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 1px;
    height: 3rem;
    width: 100%;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #1c1c1c;
    display: flex;
    align-items: center;
}

input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: calc(10px + 1vmin);
    color: white;
    width: 100%;
}

input:focus {
    outline: none;
}

#search-icon {
    color: #888888;
    height: 50%;
    padding-right: 1vmin;
}

@media (max-width: 1320px) {
    .input-box {
        border-radius: 9px;
        height: 4vmin;
        width: 80%;
        padding: 0 10px;
    }
    
    input {
        font-size: 2vmin;
    }
}

@media (max-width: 767px) {
    .input-box {
        border-radius: 9px;
        height: calc(1rem + 2vmin);
        width: 80%;
        padding: 0 5px;
    }
    
    input {
        font-size: calc(8px + 1vmin);
    }
}