.mocket-register {
    position: absolute;
    justify-content: center;
    top: 20%;
    left: max(5vw, calc((100vw - 600px) / 2));
    display: grid;
    width: 600px;
    background-color: black;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 8px #444444;
    color: white;
}

.mocket-register-header {
    text-align: center;
    padding: 2rem;
    font-size: 36px;
}

.mocket-register-input-box {
    margin-bottom: 15px;
    background-color: black;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 1px;
    height: 3rem;
    width: 500px;
    box-shadow: 0px 0px 8px #1c1c1c;
    display: flex;
    align-items: center;
}

.mocket-register-input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: calc(10px + 1vmin);
    width: 100%;
    padding: 0 15px;
}

.mocket-register-button {
    position: relative;
    background-color: black;
    cursor: pointer;
    color: white;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 2px;
    font-size: 24px;
    font-weight: bold;
    transition: 0.3s;
    justify-self: center;
    width: 100%;
    height: 60px;
    text-align: center;
    margin: 10px 0;
    z-index: 5;
}

.mocket-register-button:hover {
    color: black;
    background-color: rgb(35, 138, 255);
    border-color: black;
}

.mocket-register-button:disabled {
    color: #cccccc;
    background-color: #1c1c1c;
    border-color: #888888;
}

.mocket-register-existing {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.mocket-register-existing-button {
    margin-left: 8px;
    text-decoration: underline;
    transition: 0.3s;
    cursor: pointer;
}

.mocket-register-existing-button:hover {
    color: rgb(0, 228, 0);
}


@media (max-width: 767px) {
    .mocket-register {
        left: max(5vw, calc((100vw - 300px) / 2));
        width: 300px;
    }
    .mocket-register-header {
        padding: 2rem 0 10px 0;
        font-size: 24px;
    }
    
    .mocket-register-input-box {
        margin-bottom: 8px;
        border-radius: 8px;
        height: 1.5rem;
        width: 250px;
    }
    
    .mocket-register-input {
        padding: 0 10px;
    }

    .mocket-register-button {
        border-radius: 15px;
        font-size: 16px;
        width: 100%;
        height: 30px;
        margin: 10px 0;
    }
    
    .mocket-register-existing {
        font-size: 10px;
    }
    
    .mocket-register-existing-button {
        margin-left: 4px;
    }
}