.results-list {
    padding: 15px;
    margin-top: 10px;
    background-color: #0f0f0f;
    flex-direction: column;
    display: flex;
    box-shadow: 0px 0px 8px #1c1c1c;
    border-radius: 15px;
    max-height: 250px;
    overflow-y: scroll;
    width: 100%;
    position: relative;
    z-index: 10;
}

@media (max-width: 1320px) {
    .results-list {
        margin-top: 5px;
        border-radius: 9px;
        max-height: 120px;
        width: 80%;
    }    
}