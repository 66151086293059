.trade-panel {
    display: grid;
    margin: 0;
    padding: 10px;
    font-size: calc(10px + 1vmin);
}

.trade-panel-divider {
    background-color: #1c1c1c;
    height: 2px;
    width: 100%;
    margin: 10px 0;
}

.trade-panel-header {
    margin: 10px 0 0 0;
    display: inline-block;
}

.trade-panel-header-left {
    float: left;
}

.trade-panel-header-right {
    float: right;
}

.trade-panel-input-box {
    position: relative;
    display: inline-block;
    z-index: 5;
}

.trade-panel-input-label {
    float: left;
}

.trade-panel-input {
    float: right;
    text-align: right;
    width: 125px;
}

.trade-panel-shares {
    display: inline-block;
}

.trade-panel-shares-label {
    float: left;
}

.trade-panel-shares-live {
    float: right;
}

.trade-panel-total {
    display: inline-block;
}

.trade-panel-total-label {
    float: left;
}

.trade-panel-total-live {
    float: right;
}

.trade-panel-button {
    position: relative;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 2px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s;
    justify-self: center;
    width: 30%;
    height: 40px;
    text-align: center;
    margin: 10px 0;
    z-index: 5;
}

.trade-panel-button:hover {
    background-color: rgb(35, 138, 255);
    border-color: black;
    color: black;
}

.trade-panel-button:disabled {
    background-color: #444444;
    color: #cccccc;
}

.trade-panel-button:disabled:hover {
    cursor: default;
    background-color: #333333;
    border-color: #888888;
    color: #aaaaaa;
}

.trade-panel-button.success {
    background-color: #28a745;
}

.trade-panel-button.error {
    background-color: #dc3545;
}

.trade-panel-loading-spinner {
    margin: 3px 0 0 5px;
    border: 4px solid rgba(0, 0, 0, 0.5);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    display: inline-block;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media (max-width: 1320px) {
    .trade-panel {
        font-size: 2vmin;
    }

    .trade-panel-divider {
        height: 1px;
    }

    .trade-panel-button {
        font-size: 1.5vmin;
        height: max(35px, 3vmin);
    }

    .trade-panel-loading-spinner {
        margin: 3px 0 0 5px;
        width: 1.25vmin;
        height: 1.25vmin;
    }
}

@media (max-width: 767px) {
    .trade-panel {
        font-size: calc(8px + 1vmin);
    }

    .trade-panel-button {
        height: max(25px, 3vmin);
        font-size: calc(6px + 1vmin);
        margin: 5px 0;
    }

    .trade-panel-input {
        width: 75px;
    }

    .trade-panel-loading-spinner {
        margin: 2px 0 0 5px;
        border: 2px solid rgba(0, 0, 0, 0.5);
        border-top: 2px solid white;
        width: max(8px, 1.5vmin);
        height: max(8px, 1.5vmin);
    }
}