.positions-tile {
    background-color: black;
    cursor: pointer;
    border-radius: 15px;
    border-color: #888888;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 0px 8px #444444;
    width: 100%;
    height: 100px;
    display: inline-block;
    transition: 0.3s;
}

.positions-tile:hover {
    background-color: #1c1c1c;
}

.positions-tile-symbol {
    color: #aaaaaa;
    float: left;
    font-size: 28px;
    padding: 2rem;
}

.positions-tile-divider {
    position: relative;
    top: 7.5px;
    background-color: #888888;
    opacity: 50%;
    float: left;
    width: 1px;
    height: 85px;
}

.positions-tile-shares {
    color: #aaaaaa;
    float: left;
    font-size: 20px;
    padding: 2.3rem;
}

.positions-tile-data {
    border-radius: 5px;
    color: black;
    float: right;
    font-size: 24px;
    margin: 1.7rem;
    padding: 0.5rem;
}

.positions-tile-data.red {
    background-color: red;
}

.positions-tile-data.green {
    background-color: rgb(0, 185, 0);
}

@media (max-width: 1320px) {
    .positions-tile {
        border-radius: 9px;
        height: calc(8px + 7vmin);
    }
    
    .positions-tile-symbol {
        font-size: calc(15px + 1vmin);
        padding: 2.25vmin;
    }

    .positions-tile-divider {
        top: 0px;
        height: calc(8px + 7vmin);
    }
    
    .positions-tile-shares {
        font-size: calc(8px + 1vmin);
        padding: 2.7vmin;
    }
    
    .positions-tile-data {
        border-radius: 4px;
        font-size: calc(10px + 1vmin);
        margin: 1.75vmin;
        padding: 0.75vmin;
    }
}

@media (max-width: 767px) {
    .positions-tile-symbol {
        font-size: calc(11px + 1vmin);
        padding: 2.25vmin;
    }

    .positions-tile-shares {
        font-size: calc(8px + 1vmin);
        padding: 2.5vmin;
    }
    
    .positions-tile-data {
        border-radius: 3px;
        font-size: calc(10px + 1vmin);
        margin: 1.8vmin;
        padding: 0.5vmin;
    }
}