.result-item:hover {
    background-color: #222222;
}

.result-item-name {
    float: left;
    color: #dbdbdb;
    font-size: 30px;
}

.result-item-sym {
    float: right;
    color: #cccccc;
    font-size: 30px;
    opacity: 50%;
}

@media (max-width: 1320px) { 
    .result-item-name {
        font-size: calc(6px + 2vmin);
    }
    
    .result-item-sym {
        font-size: calc(6px + 2vmin);
    }
}