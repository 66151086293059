.quote-data-grid {
    display: grid;
    padding-bottom: 100px;
}

.quote-data-grid-header {
    margin: 40px max(5vw, calc((100vw - 1600px) / 2)) 10px;
    font-size: 36px;
    color: white;
}

.quote-data-grid-divider {
    margin: 10px max(5vw, calc((100vw - 1600px) / 2)) 20px;
    width: 100vw - 1600px;
    height: 2px;
    background-color: #1c1c1c;
}

.quote-data-grid-item {
    display: flex;
}

.quote-data-grid-left {
    float: left;
    margin: 10px 5% 10px 20%;
    width: 25%;
}

.quote-data-grid-right {
    float: right;
    margin: 10px 20% 10px 5%;
    width: 25%;
}

.quote-data-grid-vdivider {
    width: 3px;
    height: 100px;
    background-color: #1c1c1c;
}

@media (max-width: 1320px) {
    .quote-data-grid-header {
        margin: 40px 5% 0;
        font-size: max(30px, calc(10px + 2vmin));
    }
    .quote-data-grid-divider {
        height: 1px;
    }
    .quote-data-grid-left {
        margin: 5px 5% 0px 10%;
        width: 40%
    }
    .quote-data-grid-right {
        margin: 5px 10% 0px 5%;
        width: 40%
    }
    
    .quote-data-grid-vdivider {
        width: 1px;
        height: calc(32px + 4vmin);
    }
}

@media (max-width: 767px) {
    .quote-data-grid {
        padding-bottom: 50px;
    }
    .quote-data-grid-header {
        margin: 20px 5% 0;
        font-size: max(24px, calc(10px + 2vmin));
    }    
    .quote-data-grid-left {
        margin: 5px 5%;
        width: 40%
    }
    
    .quote-data-grid-right {
        margin: 5px 5%;
        width: 40%
    }
    
    .quote-data-grid-vdivider {
        width: 1px;
        height: 60px;
        height: calc(32px + 5vmin);
    }
}