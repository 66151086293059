.alert {
    position: absolute;
    top: 10px;
    display: inline-block;
    left: 25%;
    right: 25%;
    padding: 0;
    z-index: 15;
}

.alert-type {
    height: 50px;
    box-shadow: 0px 0px 10px 10px #171717;
    border-radius: 15px;
    border-width: 3px;
    border-style: solid;
}

.alert-type.error {
    border-color: rgba(255, 70, 70, 0.5);
    background-color: #fd7066;
    color: white;
}

.alert-type.warning {
    border-color: gold;
    background-color: #f8df70;
    color: black;
}

.alert-type.success {
    border-color: rgb(73, 184, 63);
    background-color: #81ff87;
    color: black;
}

.alert-message {
    padding: 0.5rem 20px;
    font-size: 24px;
    float: left;
    align-items: center;
}

.alert-dismiss {
    padding: 0.5rem 10px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    float: right;
}

.alert-dismiss:hover {
    color: black;
}


@media (max-width: 1320px) {
    .alert {
        top: 20px;
        left: 20%;
        right: 20%;
    }

    .alert-type {
        height: 40px;
    }
    
    .alert-message {
        padding: 0.5rem 0 0 10px;
        font-size: 18px;
    }
    
    .alert-dismiss {
        padding: 0.5rem 10px 0 10px;
        font-size: 18px;
    }
}

@media (max-width: 767px) { 
    .alert {
        left: 15%;
        right: 15%;
    }

    .alert-type {
        height: 30px;
    }
    
    .alert-message {
        padding: 0.55rem 0 0 10px;
        font-size: 10px;
    }
    
    .alert-dismiss {
        padding: 0.55rem 10px 0 0;
        font-size: 10px;
    }
}