.trade-actions {
    color: white;
    display: flex;
}

.trade-actions-buy {
    margin: 10px 5% 10px 20%;
    width: 25%;
}

.trade-actions-buy-lone {
    margin: 10px 20% 10px 20%;
    color: white;
}

.trade-actions-sell {
    margin: 10px 20% 10px 5%;
    width: 25%;
}

.accordion {
    background-color: black;
    color: white;
    cursor: pointer;
    height: 60px;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    border-color: #888888;
    border-style: solid;
    border-width: 2px;
    font-weight: bold;
    font-size: 20px;
    outline: none;
    transition: 0.3s;
}

.accordion.left {
    position: relative;
    float: left;
    z-index: 0;
}

.accordion.right {
    position: relative;
    float: right;
    z-index: 0;
}

.active, .accordion:hover {
    background-color: rgb(35, 138, 255);
    border-color: rgb(35, 138, 255);
    color: black;
}

.panel {
    background-color: black;
    border-radius: 8px;
    border-color: #888888;
    border-style: solid;
    border-width: 0px;
    border-top: 0;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    transition: 0.3s;
}

.panel.left {
    float: left;
}

.panel.right {
    float: right;
}

@media (max-width: 1320px) {
    .trade-actions-buy {
        margin: 10px 5% 10px 10%;
        width: 35%;
    }
    
    .trade-actions-buy-lone {
        margin: 10px 10%;
        color: white;
    }
    
    .trade-actions-sell {
        margin: 10px 10% 10px 5%;
        width: 35%;
    }

    .accordion {
        height: 60px;
        font-size: calc(10px + 1vmin);
    }
}

@media (max-width: 767px) {
    .trade-actions-buy {
        margin: 10px 5%;
        width: 40%;
    }
    
    .trade-actions-buy-lone {
        margin: 10px 5%;
        color: white;
    }
    
    .trade-actions-sell {
        margin: 10px 5%;
        width: 40%;
    }

    .accordion {
        height: max(40px, 8vmin);
    }
}
