.mocket-footer {
    padding: 1px 0;
    position: absolute;
    width: 100%;
    background-color: #0f0f0f;
}

.mocket-footer-items {
    font-size: 16px;
    margin: 10px;
    display: flex;
    color: #888888;
    text-align: center;
    justify-content: center;
}

.mocket-footer-link {
    color: #888888;
    text-decoration: none;
}

.mocket-footer-link:hover {
    cursor: pointer;
    color: rgb(35, 138, 255);
    text-decoration: underline;
}

@media (max-width: 767px) {
    .mocket-footer-items {
        font-size: 12px;
    }
}
